<template>
  <div id="logon">
    <div class="maximal">
      <a-tabs type="card" @change="handleChange" style="padding-top:30px">
        <a-tab-pane key="1" tab="个人注册">
          <!--个人注册-->
          <a-form-model :model="user" :rules="rules" ref="personal">
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="用户账号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="username">
                  <a-input size="small" v-model="user.username" :maxLength="100" allowClear placeholder="请输入用户账号"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="password">
                  <a-input-password size="small" v-model="user.password" :maxLength="255" allowClear placeholder="请输入密码"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="团队负责人" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="realname">
                  <a-input size="small" v-model="user.realname" :maxLength="100" allowClear placeholder="请输入团队负责人"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系电话" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="phone">
                  <a-input size="small" v-model="user.phone" :maxLength="45" allowClear placeholder="请输入联系电话" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="email">
                  <a-input size="small" v-model="user.email" :maxLength="45" allowClear placeholder="请输入邮箱"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="所在驿站" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="stationId">
                  <a-select default-value="所在驿站" v-model="user.stationId" size="small" allowClear  placeholder="请选择所在驿站">
                    <a-select-option :value="item.id" v-for="(item,index) in postStationList" :key="index">
                      {{ item.stationName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="团队人数" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="numberOfTeams">
                  <a-input size="small" v-model="user.numberOfTeams" :maxLength="3" allowClear placeholder="请输入团队人数"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" style="padding-left: 15px">
                <a-form-model-item label="项目简介" :labelCol="labelCol2" :wrapperCol="wrapperCol2" prop="introduction">
                  <a-textarea v-model="user.introduction" class="introduce" :maxLength="255" placeholder="请输入项目简介"/>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="2" tab="企业注册">
          <!--企业注册-->
          <a-form-model :model="company" :rules="rules2" ref="enterprise">
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="用户账号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="username">
                  <a-input size="small" v-model="company.username" :maxLength="15" allowClear placeholder="请输入用户账号"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="password">
                  <a-input-password size="small" v-model="company.password" :maxLength="255" allowClear placeholder="请输入密码" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="公司名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="company">
                  <a-input size="small" v-model="company.company" :maxLength="100" allowClear placeholder="请输入公司名称"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input size="small" v-model="company.realname" :maxLength="100" allowClear placeholder="请输入联系人" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="联系电话" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="phone">
                  <a-input size="small" v-model="company.phone" :maxLength="45" allowClear placeholder="请输入联系电话" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="email">
                  <a-input size="small" v-model="company.email" :maxLength="45" allowClear placeholder="请输入邮箱"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="所在驿站" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="stationId">
                  <a-select default-value="所在驿站" v-model="company.stationId" size="small" allowClear  placeholder="请选择所在驿站">
                    <a-select-option :value="item.id" v-for="(item,index) in postStationList" :key="index">
                      {{ item.stationName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="所属行业" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-select default-value="所属行业" v-model="company.Industry" size="small" allowClear  placeholder="请选择所属行业">
                    <a-select-option :value="item.id" v-for="(item,index) in industryList" :key="index">
                      {{ item.itemText }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" style="padding-left: 15px">
                <a-form-model-item label="公司简介" :labelCol="labelCol2" :wrapperCol="wrapperCol2" prop="introduction">
                  <a-textarea v-model="company.introduction" class="introduce" :maxLength="255" placeholder="请输入公司简介" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
      <a-button type="primary" class="register" @click="register(tab)" size="large">立即注册</a-button>
    </div>
  </div>
</template>

<script>

import { getStation, getIndustry } from '@/api/index'
import { register } from '@/api/login'

export default {
  name: 'Register',
  data() {
    return {
      value: true,
      color: 1,
      toggle: 'toggle',
      user: {
        username: '', // 用户名
        password: '', // 密码
        realname: '', // 团队负责人/联系人
        phone: '', // 联系电话
        email: '', // 邮箱
        stationId: undefined, // 所在地区/所在地/所属驿站
        numberOfTeams: '', // 团队人数
        company: '', // 公司名称
        introduction: '', // 公司简介
        identityType: 2 // 用户类型（1-站内 2-个人 3-企业）
      },
      company: {
        username: '', // 用户名
        password: '', // 密码
        realname: '', // 团队负责人/联系人
        phone: '', // 联系电话
        email: '', // 邮箱
        stationId: undefined, // 所在地区/所在地/所属驿站
        numberOfTeams: '', // 团队人数
        company: '', // 公司名称
        Industry: undefined, // 所属行业
        introduction: '', // 项目简介/公司简介
        identityType: 3 // 用户类型（1-站内 2-个人 3-企业）
      },
      postStationList: [], // 驿站列表
      industryList: [], // 行业列表
      tab: '1',
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 9
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 12
        }
      },
      labelCol2: {
        xs: {
          span: 24
        },
        sm: {
          span: 4
        }
      },
      wrapperCol2: {
        xs: {
          span: 24
        },
        sm: {
          span: 18
        }
      },
      rules: {
        username: [{
          required: true,
          message: '用户账号不能为空',
          trigger: 'blur'
        },
        {
          pattern: /[u4E00-u9FA5]{4,15}/g,
          message: '不能为中文且至少4位',
          trigger: 'blur'
        },
        {
          pattern: /^[a-zA-Z].*/,
          message: '只能以字母开头',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z]).{12,225}$/,
          message: '必须包含大小写且12位',
          trigger: 'blur'
        }],
        realname: [{
          required: true,
          message: '团队负责人不能为空',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '联系电话不能为空',
          trigger: 'blur'
        },
        {
          required: true,
          pattern: /^[0-9]+$/,
          message: '请正确输入号码'
        }],
        stationId: [{
          required: true,
          message: '所在驿站不能为空',
          trigger: 'blur'
        }],
        introduction: [{
          required: true,
          message: '项目简介不能为空',
          trigger: 'blur'
        }],
        email: [
          {
            required: true,
            message: '邮箱名称不能为空',
            trigger: 'blur'
          },
          {
            pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            message: '*email格式有误',
            trigger: 'blur'
          }]
      },
      rules2: {
        username: [{
          required: true,
          message: '用户账号不能为空',
          trigger: 'blur'
        },
        {
          pattern: /[u4E00-u9FA5]{4,15}/g,
          message: '不能为中文且至少4位',
          trigger: 'blur'
        },
        {
          pattern: /^[a-zA-Z].*/,
          message: '只能以字母开头',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z]).{12,225}$/,
          message: '必须包含大小写且12位',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '联系电话不能为空',
          trigger: 'blur'
        },
        {
          required: true,
          pattern: /^[0-9]+$/,
          message: '请正确输入号码'
        }],
        stationId: [{
          required: true,
          message: '所在驿站不能为空',
          trigger: 'blur'
        }],
        introduction: [{
          required: true,
          message: '公司简介不能为空',
          trigger: 'blur'
        }],
        company: [{
          required: true,
          message: '公司名称不能为空',
          trigger: 'blur'
        }],
        email: [
          {
            required: true,
            message: '邮箱名称不能为空',
            trigger: 'blur'
          },
          {
            pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            message: '*email格式有误',
            trigger: 'blur'
          }]
      }
    }
  },
  methods: {
    /**
     * @description:清除表单（个人）
     * @param {*} tab
     */
    handleChange(tab) {
      this.user.company = ''
      // eslint-disable-next-line eqeqeq
      if (tab === '1') {
        this.$refs.enterprise.resetFields()
        this.tab = tab
      } else {
        this.$refs.personal.resetFields()
        this.tab = tab
      }
    },

    /**
     * @description:注册
     *
     */
    register(tab) {
      if (tab === '1') {
        this.$refs.personal.validate(valid => {
          if (valid) {
            register(this.user).then(res => {
              if (res.code === 200) {
                this.$message.success(res.message)
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            return false
          }
        })
      } else {
        this.$refs.enterprise.validate(valid => {
          if (valid) {
            register(this.company).then(res => {
              if (res.code === 200) {
                this.$message.success(res.message)
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            return false
          }
        })
      }
    }
  },
  created() {
    /**
     * @description:获取驿站列表
     *
     */
    getStation().then(res => {
      this.postStationList = res.result
    })
    /**
       * @description:获取行业列表
       *
       */
    getIndustry().then(res => {
      this.industryList = res.result
    })
  }
}
</script>

<style scoped>
@import "../assets/css/register.css";
</style>
